// src/services/auth.js

class AuthService {

  async login(username, password) {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });

    const data = await response.json();
    if (response.ok) {
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token); // Assumindo que o refresh token é retornado
      localStorage.setItem('role', data.role);
      localStorage.setItem('user_id', data.id);
    }
    return data;
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('role');
    localStorage.removeItem('user_id');
    
  }

  getCurrentUser() {
    const token = localStorage.getItem('access_token');
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('user_id');
    if (token && role) {
      return { token, role, userId };
    }
    return null;
  }
}

export default new AuthService();